import { render, staticRenderFns } from "./PhonePicker.vue?vue&type=template&id=6880dd6e&scoped=true"
import script from "./PhonePicker.vue?vue&type=script&lang=ts"
export * from "./PhonePicker.vue?vue&type=script&lang=ts"
import style0 from "./PhonePicker.vue?vue&type=style&index=0&id=6880dd6e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6880dd6e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VExpandTransition } from 'vuetify/lib/components/transitions';
import { VScaleTransition } from 'vuetify/lib/components/transitions';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VExpandTransition,VScaleTransition,VTextField})
