
import Vue from "vue";
import ImageViewer from "@/components/viewers/Image.vue";

export default Vue.extend({
  name: "FileUploader",

  components: { ImageViewer },

  props: {
    value: {
      required: false,
      default: null
    },
    label: {
      required: false,
      type: String,
      default: ""
    },
    rules: {
      required: false,
      type: Array,
      default: () => []
    },
    accept: {
      required: false,
      type: String,
      default: ""
    },
    truncateLength: {
      required: false,
      type: String,
      default: ""
    },
    dense: {
      required: false,
      type: Boolean,
      default: false
    },
    outlined: {
      required: false,
      type: Boolean,
      default: false
    },
    autofocus: {
      required: false,
      type: Boolean,
      default: false
    },
    hideDetails: {
      required: false,
      type: Boolean,
      default: false
    },
    errorMessages: {
      required: false,
      type: Array,
      default: () => []
    }
  },

  data: () => ({
    model: null as File | null,
    imagePreview: "" as string | ArrayBuffer | null,
    errors: [] as any
  }),

  watch: {
    errorMessages: {
      immediate: true,
      handler() {
        this.errors = this.errorMessages;
      }
    },
    value: {
      immediate: true,
      handler() {
        if (typeof this.value === "string") {
          this.model = new File([], this.value || "");
        } else {
          this.model = this.value;
        }
      }
    }
  },

  methods: {
    toBase64(file: File): Promise<string | ArrayBuffer | null> {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    },
    async changeData(): Promise<void> {
      if (this.model?.type.includes("image")) {
        this.imagePreview = await this.toBase64(this.model);
      } else {
        this.imagePreview = "";
      }

      this.$emit("input", this.model);
    }
  }
});
