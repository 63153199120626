var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',[_c('div',{staticClass:"caption",class:{
        'primary-label': _vm.focus,
        'error-label': _vm.phone.formatted && !_vm.phone.valid
      },staticStyle:{"height":"17px"}},[_c('v-scale-transition',[(_vm.phoneNumber || _vm.focus)?_c('span',[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e()])],1),_c('vue-tel-input',{staticClass:"phone-picker",class:{
        'invalid-phone': _vm.phone.formatted && !_vm.phone.valid
      },attrs:{"inputOptions":{
        placeholder: _vm.focus ? '' : _vm.label
      },"default-country":"US"},on:{"blur":function($event){_vm.focus = false},"focus":function($event){_vm.focus = true},"input":_vm.input},model:{value:(_vm.phoneNumber),callback:function ($$v) {_vm.phoneNumber=$$v},expression:"phoneNumber"}})],1),_c('div',{staticStyle:{"height":"20px"}},[_c('v-expand-transition',[(_vm.phone.formatted && !_vm.phone.valid)?_c('div',{staticClass:"caption error-label"},[_vm._v(" Поле не валидно ")]):_vm._e()])],1),_c('v-text-field',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],ref:"input",attrs:{"rules":_vm.rules.concat( [function () { return _vm.phone.valid || ''; }])},model:{value:(_vm.phoneNumber),callback:function ($$v) {_vm.phoneNumber=$$v},expression:"phoneNumber"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }